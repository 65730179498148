export const organizationErrorI18n = {
    'api-error.team-limit-reached.title': 'Team limit reached',
    'api-error.team-limit-reached.message': 'Cannot perform this operation',
    'api-error.billing-entity-limit-reached.title': 'Billing entity limit reached',
    'api-error.billing-entity-limit-reached.message': 'Cannot perform this operation',
    'api-error.billing-entity-update-non-free-tier.title': 'Update non free tier organization billing entity',
    'api-error.billing-entity-update-non-free-tier.message': 'Cannot perform this operation',
    'api-error.cannot-update-billing-entity-currency.title': 'Cannot update billing entity currency',
    'api-error.cannot-update-billing-entity-currency.message': 'Cannot perform this operation',
    'api-error.billing-entity-currency-update-invalid-argument.title':
        'Invalid argument for billing entity currency update',
    'api-error.billing-entity-currency-update-invalid-argument.message': 'Cannot perform this operation',
    'api-error.two-sync.title': 'Two sync failed',
    'api-error.two-sync.message': 'Cannot perform this operation',
    'api-error.accounting-sync.title': 'Accounting sync failed',
    'api-error.accounting-sync.message': 'Cannot perform this operation',
    'api-error.invalid-billing-entity-accounting-data.title': 'Invalid billing entity accounting data',
    'api-error.invalid-billing-entity-accounting-data.message': 'Cannot perform this operation',
    'api-error.organization-without-default-entity.title': 'Organization without default billing entity',
    'api-error.organization-without-default-entity.message': 'Cannot perform this operation',
    'api-error.invoice-countries-used-in-other-entity.title': 'Invoice countries used in other entity',
    'api-error.invoice-countries-used-in-other-entity.message': 'Cannot perform this operation',
    'api-error.subscription-already-exists.title': 'Subscription already exists',
    'api-error.subscription-already-exists.message': 'Another subscription exists with overlapping dates',
    'api-error.invalid-subscription-billing-frequency.title': 'Invalid subscription billing frequency',
    'api-error.invalid-subscription-billing-frequency.message':
        'Only monthly subscription allowed for user based platform tiers',
    'api-error.subscription-already-invoiced.title': 'Subscription already invoiced',
    'api-error.subscription-already-invoiced.message': 'You must create a new subscription instead.',
    'api-error.incorrect-platform-tier.title': 'Incorrect platform tier',
    'api-error.incorrect-platform-tier.message': 'Cannot perform this operation',
    'api-error.upgrade-trial-organization.title': 'Cannot upgrade trial organization',
    'api-error.upgrade-trial-organization.message': 'Cannot perform this operation',
    'api-error.invalid-bundling-and-tier.title': 'Invalid bundling and tier combination',
    'api-error.invalid-bundling-and-tier.message': 'Cannot perform this operation',
    'api-error.invalid-payment-schema-and-tier.title': 'Invalid payment schema and tier combination',
    'api-error.invalid-payment-schema-and-tier.message': 'Cannot perform this operation',
    'api-error.invalid-billing-frequency.title': 'Invalid billing frequency',
    'api-error.invalid-billing-frequency.message': 'Cannot perform this operation',
    'api-error.organization-model-change-with-requests.title': 'Organization model change with request',
    'api-error.organization-model-change-with-requests.message': 'Cannot perform this operation',
    'api-error.organization-model-change-with-discounts.title':
        'Not allowed to change model with active discounts',
    'api-error.organization-model-change-with-discounts.message': 'Cannot perform this operation',
    'api-error.subscription-not-active.title': 'Subscription not active',
    'api-error.subscription-not-active.message': 'Cannot perform this operation',
    'api-error.invalid-subscription-start-date.title': 'Invalid subscription start date',
    'api-error.invalid-subscription-start-date.message': 'Cannot perform this operation',
    'api-error.subscription-not-invoiced.title': 'Subscription not invoiced',
    'api-error.subscription-not-invoiced.message': 'Cannot perform this operation',
    'api-error.invalid-subscription-currency.title': 'Subscription currency not valid',
    'api-error.invalid-subscription-currency.message': 'Cannot perform this operation',
    'api-error.subscription-not-found.title': 'Subscription not found',
    'api-error.subscription-not-found.message': 'Cannot perform this operation',
    'api-error.subscription-already-started.title': 'Subscription already started',
    'api-error.subscription-already-started.message': 'Cannot perform this operation',
    'api-error.billing-entity-has-invoice-entries.title': 'Cannot set billing entity status to trial',
    'api-error.billing-entity-has-invoice-entries.message': 'Billing entity has invoice entries',
};
