import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

export const useNavigateOrganizationContract = (rootPath: string) => {
    const history = useHistory();

    const getCreateOrganizationContractLink = () => `${rootPath}/create`;

    const handleOpenViewOrganizationContract = (_: UUID) => {
        //history.push(`${rootPath}/${id}/view`);
    };

    const goBack = () => {
        history.push(rootPath);
    };

    return {
        getCreateOrganizationContractLink,
        handleOpenViewOrganizationContract,
        goBack,
    };
};
