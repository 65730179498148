import React, { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { EnumRoute, UUIDRoute } from '@hofy/router';

import { EmailPreviewSlideout } from '../../components/domain/emailLogs/EmailPreviewSlideout';
import { getUserLink } from '../../components/routing/adminLinks';
import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { InvoiceDetailTabs } from '../../store/invoices/types/InvoiceDetailTabs';
import { useNavigateBillingEntity } from '../../store/invoicing/billingEntities/useNavigateBillingEntity';
import { AdminInvoicingTab } from '../../store/invoicing/types/AdminInvoicingTab';
import { useNavigateOrganizationContract } from '../../store/organizationContracts/useNavigateOrganizationContract';
import { OrganizationTab } from '../../store/organizations/types/OrganizationTab';
import { useNavigateSubscription } from '../../store/subscription/useNavigateSubscription';
import { BillingEntityTabRoute } from '../invoicingPage/billingEntities/BillingEntitiesRouter';
import { BillingEntityDetailsOverlay } from '../invoicingPage/billingEntities/billingEntityDetailsOverlay/BillingEntityDetailsOverlay';
import { CreateUpdateBillingEntityOverlay } from '../invoicingPage/billingEntities/createUpdateBillingEntityOverlay/CreateUpdateBillingEntityOverlay';
import { ItemSlideoutRouter } from '../itemsPage/ItemSlideoutRouter';
import { CreateOrganizationSlideout } from './createOrganizationSlideout/CreateOrganizationSlideout';
import { CreateSubscriptionSlideoutContainer } from './createSubscriptionSlideout/CreateSubscriptionSlideoutContainer';
import { CreateUserSlideout } from './createUserSlideout/CreateUserSlideout';
import { CreateOrganizationContractOverlay } from './organizationContractsTab/CreateOrganizationContractOverlay';
import { OrganizationDetailsPage } from './OrganizationDetailsPage';
import { OrganizationsPage } from './OrganizationsPage';
import { UpdateAddonConfigurationSlideout } from './updateAddonConfigurationSlideout/UpdateAddonConfigurationSlideout';
import { UpdateContractSettingsSlideoutOverlay } from './updateContractSettingsSlideout/ContractSettingsSlideoutOverlay';
import { UpdateSubscriptionSlideoutContainer } from './updateSubscriptionSlideout/UpdateSubscriptionSlideoutContainer';

export const OrganizationsRouter: FC = () => {
    const history = useHistory();
    const { goBack } = useGoBack();
    const handleOpenOrganization = (id: UUID) => {
        history.push(`${AdminNavLink.Organizations}/${id}/details`);
    };

    const handleOpenNewOrganization = () => {
        history.push(`${AdminNavLink.Organizations}/list/new`);
    };

    return (
        <Route>
            <Switch>
                <OrganizationIdRoute path={`${AdminNavLink.Organizations}/:organizationId(${pathUuid})/:tab`}>
                    {({ organizationId }) => <OrganizationDetailsRouter organizationId={organizationId} />}
                </OrganizationIdRoute>
                <Route path={AdminNavLink.Organizations}>
                    <>
                        <OrganizationsPage
                            onNewOrganization={handleOpenNewOrganization}
                            onOpenOrganization={handleOpenOrganization}
                        />
                        <Route exact path='/organizations/list/new'>
                            <CreateOrganizationSlideout onClose={() => goBack(AdminNavLink.Organizations)} />
                        </Route>
                    </>
                </Route>
            </Switch>
        </Route>
    );
};

enum Method {
    Create = 'create',
    Edit = 'edit',
}

const OrganizationIdRoute = UUIDRoute('organizationId', Route);
const BillingEntityRoute = UUIDRoute('billingEntityId', Route);
const ContractSettingsRoute = UUIDRoute('contractSettingId', Route);
const AddonConfigurationRoute = UUIDRoute('addonId', Route);
const SubscriptionRoute = UUIDRoute('subscriptionId', Route);
const OrganizationTabRoute = EnumRoute<OrganizationTab>('organizationTab', OrganizationTab, Route);
const EmailPreviewSlideoutRoute = UUIDRoute('emailId', Route);

interface OrganizationDetailsRouterProps {
    organizationId: UUID;
}

const OrganizationDetailsRouter: FC<OrganizationDetailsRouterProps> = ({ organizationId }) => {
    const { goBack } = useGoBack();
    const history = useHistory();

    const handleOpenCreateUser = (id: UUID) => {
        history.push(`${AdminNavLink.Organizations}/${id}/${OrganizationTab.Users}/${Method.Create}`);
    };
    const handleOpenInvoice = (id: UUID) => {
        history.push(
            `${AdminNavLink.Invoicing}/${AdminInvoicingTab.Invoices}/${id}/${InvoiceDetailTabs.Details}`,
        );
    };
    const handleOpenItem = (id: UUID) => {
        history.push(`${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Devices}/${id}`);
    };
    const handleUpdateContractSetting = (organizationId: UUID, contractSettingId: UUID) => {
        history.push(
            `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.ContractSettings}/${contractSettingId}/${Method.Edit}`,
        );
    };
    const handleUpdateAddonConfiguration = (addonId: UUID) => {
        history.push(
            `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Addons}/${addonId}/${Method.Edit}`,
        );
    };
    const handleOpenUser = (id: UUID) => {
        history.push(getUserLink(id));
    };
    const handleOpenEmail = (emailId: UUID) => {
        history.push(`${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Emails}/${emailId}`);
    };
    const handleChangeTab = (id: UUID, tab: OrganizationTab) => {
        history.push(`${AdminNavLink.Organizations}/${id}/${tab}`);
    };

    const {
        navigateBillingEntityDetails,
        navigateBillingEntityTab,
        navigateUpdateBillingEntity,
        navigateBillingEntityList,
        getCreateBillingEntityLink,
    } = useNavigateBillingEntity(
        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.BillingEntities}`,
    );

    const {
        handleOpenCreateSubscription,
        handleOpenEditSubscription,
        goBack: subscriptionGoBack,
    } = useNavigateSubscription(
        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Subscriptions}`,
    );

    const {
        getCreateOrganizationContractLink,
        handleOpenViewOrganizationContract,
        //goBack: organizationContractGoBack,
    } = useNavigateOrganizationContract(
        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.OrganizationContracts}`,
    );

    return (
        <OrganizationIdRoute path='/organizations/:organizationId'>
            {({ organizationId }) => (
                <>
                    <Route
                        path={`/organizations/:organizationId/${OrganizationTab.BillingEntities}/${Method.Create}`}
                    >
                        <CreateUpdateBillingEntityOverlay
                            organizationId={organizationId}
                            onClose={() =>
                                goBack(
                                    `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.BillingEntities}`,
                                )
                            }
                        />
                    </Route>
                    <Route
                        path={`/organizations/:organizationId/${OrganizationTab.OrganizationContracts}/${Method.Create}`}
                    >
                        <CreateOrganizationContractOverlay
                            organizationId={organizationId}
                            onClose={() =>
                                goBack(
                                    `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.OrganizationContracts}`,
                                )
                            }
                        />
                    </Route>
                    <Route path={`/organizations/:organizationId/${OrganizationTab.Subscriptions}/create`}>
                        <CreateSubscriptionSlideoutContainer
                            organizationId={organizationId}
                            onClose={subscriptionGoBack}
                        />
                    </Route>
                    <ContractSettingsRoute
                        path={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.ContractSettings}/:contractSettingId/${Method.Edit}`}
                    >
                        {({ contractSettingId }) => (
                            <UpdateContractSettingsSlideoutOverlay
                                organizationId={organizationId}
                                contractSettingId={contractSettingId}
                                onClose={() =>
                                    goBack(
                                        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.ContractSettings}`,
                                    )
                                }
                            />
                        )}
                    </ContractSettingsRoute>
                    <AddonConfigurationRoute
                        path={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.Addons}/:addonId/${Method.Edit}`}
                    >
                        {({ addonId }) => (
                            <UpdateAddonConfigurationSlideout
                                addonId={addonId}
                                organizationId={organizationId}
                                onClose={() =>
                                    goBack(
                                        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Addons}`,
                                    )
                                }
                            />
                        )}
                    </AddonConfigurationRoute>
                    <BillingEntityRoute
                        path={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.BillingEntities}/:billingEntityId/${Method.Edit}`}
                    >
                        {({ billingEntityId }) => (
                            <CreateUpdateBillingEntityOverlay
                                onClose={navigateBillingEntityList}
                                billingEntityId={billingEntityId}
                                organizationId={organizationId}
                            />
                        )}
                    </BillingEntityRoute>
                    <BillingEntityTabRoute
                        path={`${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.BillingEntities}/:billingEntityId(${pathUuid})/:billingEntityTab`}
                    >
                        {({ billingEntityId, billingEntityTab }) => (
                            <BillingEntityDetailsOverlay
                                billingEntityId={billingEntityId}
                                billingEntityTab={billingEntityTab}
                                onChangeTab={tab => navigateBillingEntityTab(billingEntityId, tab)}
                                onInvoiceClick={handleOpenInvoice}
                                onUpdateBillingEntity={navigateUpdateBillingEntity}
                                onClose={navigateBillingEntityList}
                            />
                        )}
                    </BillingEntityTabRoute>
                    <Route
                        path={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.Users}/${Method.Create}`}
                    >
                        <CreateUserSlideout
                            organizationId={organizationId}
                            onClose={() =>
                                goBack(
                                    `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Users}`,
                                )
                            }
                        />
                    </Route>
                    <EmailPreviewSlideoutRoute
                        path={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.Emails}/:emailId(${pathUuid})`}
                    >
                        {({ emailId }) => (
                            <EmailPreviewSlideout
                                emailId={emailId}
                                onClose={() =>
                                    goBack(
                                        `${AdminNavLink.Organizations}/${organizationId}/${OrganizationTab.Emails}`,
                                    )
                                }
                            />
                        )}
                    </EmailPreviewSlideoutRoute>
                    <SubscriptionRoute
                        path={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.Subscriptions}/:subscriptionId(${pathUuid})/edit`}
                    >
                        {({ subscriptionId }) => (
                            <UpdateSubscriptionSlideoutContainer
                                organizationId={organizationId}
                                subscriptionId={subscriptionId}
                                onClose={subscriptionGoBack}
                            />
                        )}
                    </SubscriptionRoute>
                    <OrganizationTabRoute
                        path={`${AdminNavLink.Organizations}/:organizationId/:organizationTab`}
                    >
                        {({ organizationTab }) => (
                            <OrganizationDetailsPage
                                organizationId={organizationId}
                                organizationTab={organizationTab}
                                onOpenCreateUser={handleOpenCreateUser}
                                onInvoiceClick={handleOpenInvoice}
                                onItemClick={handleOpenItem}
                                onOpenUser={handleOpenUser}
                                getCreateBillingEntityLink={getCreateBillingEntityLink}
                                onOpenBillingEntity={navigateBillingEntityDetails}
                                onUpdateContractSetting={handleUpdateContractSetting}
                                onUpdateAddonConfiguration={handleUpdateAddonConfiguration}
                                onCreateSubscription={handleOpenCreateSubscription}
                                getCreateOrganizationContractLink={getCreateOrganizationContractLink}
                                onSubscriptionClick={handleOpenEditSubscription}
                                onOrganizationContractClick={handleOpenViewOrganizationContract}
                                onOpenEmail={handleOpenEmail}
                                onChangeTab={organizationTab =>
                                    handleChangeTab(organizationId, organizationTab)
                                }
                            />
                        )}
                    </OrganizationTabRoute>
                    <ItemSlideoutRouter
                        base={`${AdminNavLink.Organizations}/:organizationId/${OrganizationTab.Devices}`}
                    />
                </>
            )}
        </OrganizationIdRoute>
    );
};
